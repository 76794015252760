<div
  class="logo-stack"
  [style.--size.px]="size()"
  [class.logo-stack--with-hover-effect]="!hoverEffectDisabled()"
>
  <ng-content />

  @if (hiddenCount() > 0) {
    <div
      [ccTooltip]="'common.strings.plusMore' | translate: { count: hiddenCount() }"
      class="logo-stack__trailing"
    >
      {{ hiddenCount() }}
    </div>
  }
</div>
