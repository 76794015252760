import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'cc-logo-stack',
  standalone: true,
  imports: [TooltipDirective, TranslateModule],
  templateUrl: './logo-stack.component.html',
  styleUrl: './logo-stack.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoStackComponent {
  size = input.required<number>();
  hiddenCount = input(0);
  hoverEffectDisabled = input(false);
}
