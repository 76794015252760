import { computed, DestroyRef, inject, Injectable, signal, type Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, UrlSerializer } from '@angular/router';
import { BehaviorSubject, filter, map, switchMap, tap, type Observable } from 'rxjs';

import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';

export enum WorkspaceCounterType {
  EmailInbox = 'EmailInbox',
  Direct = 'Direct',
  Chat = 'Chat',
}

interface WorkspaceUnreadConversationsCountResponse {
  workspaceId: number | undefined;
  unreadCount: number;
  counterType: WorkspaceCounterType;
}

export interface WorkspaceUnreadConversationsCount {
  workspaceId: number | undefined;
  unreadConversationsCount: number;
  counterType: WorkspaceCounterType;
}

@Injectable({
  providedIn: 'root',
})
export class UnreadCountersService {
  private readonly _counts = signal<WorkspaceUnreadConversationsCount[]>([]);
  private readonly _totalUnreadCount = computed(() => {
    return this._counts()
      .filter(Boolean)
      .reduce((acc, { unreadConversationsCount }) => acc + unreadConversationsCount, 0);
  });

  private readonly _updateCounters$ = new BehaviorSubject<void>(null);

  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly destroyRef = inject(DestroyRef);
  private readonly userService = inject(UserService);

  get totalUnreadCount(): Signal<number> {
    return this._totalUnreadCount;
  }

  constructor() {
    this.loadUnreadCounters();
  }

  getTotalUnreadCountByCounterType(counterType: WorkspaceCounterType): Signal<number> {
    return computed(() => {
      return this._counts()
        .filter(Boolean)
        .reduce((acc, { unreadConversationsCount, counterType: type }) => {
          return type === counterType ? acc + unreadConversationsCount : acc;
        }, 0);
    });
  }

  getWorkspacesUnreadCountByCounterType(
    workspaceIds: number[],
    counterType: Omit<WorkspaceCounterType, WorkspaceCounterType.Direct>,
  ): Signal<number> {
    return computed(() => {
      return this._counts()
        .filter(Boolean)
        .filter(({ workspaceId, counterType: type }) => workspaceIds.includes(workspaceId) && type === counterType)
        .reduce((acc, { unreadConversationsCount }) => acc + unreadConversationsCount, 0);
    });
  }

  updateUnreadCounters(): void {
    this._updateCounters$.next();
  }

  private loadUnreadCounters(): void {
    this.userService.isAuthenticated$
      .asObservable()
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => this._updateCounters$),
        switchMap(() => this.getWorkspacesCounters()),
        tap((workspacesCounters) => this._counts.set(workspacesCounters)),
      )
      .subscribe();
  }

  private getWorkspacesCounters(
    workspaceIds: number[] = [],
    types: WorkspaceCounterType[] = [
      WorkspaceCounterType.EmailInbox,
      WorkspaceCounterType.Direct,
      WorkspaceCounterType.Chat,
    ],
  ): Observable<WorkspaceUnreadConversationsCount[]> {
    const mapWorkspaceCounter = (c: WorkspaceUnreadConversationsCountResponse): WorkspaceUnreadConversationsCount => ({
      workspaceId: c.workspaceId,
      unreadConversationsCount: c.unreadCount ?? 0,
      counterType: c.counterType,
    });

    const countersUrlTree = this.router.createUrlTree(['api', 'stream-conversations', 'counters'], {
      queryParams: {
        types: types.length ? types : undefined,
        workspacesIds: workspaceIds.length ? workspaceIds : undefined,
      },
    });
    const countersPath = this.serializer.serialize(countersUrlTree);

    return this.http
      .getV2<WorkspaceUnreadConversationsCountResponse[]>(countersPath)
      .pipe(map((counters) => counters.map(mapWorkspaceCounter)));
  }
}
