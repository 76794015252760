import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ReassignTaskModalComponent } from '@clover/tasks/modals/reassign-task-modal/reassign-task-modal.component';
import { TaskPerformer, TaskPerformerCompany } from '@conversations/tasks/tasks.model';
import { TasksService } from '@conversations/tasks/tasks.service';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ModalService } from '@core/services/modal.service';
import { LogoStackComponent } from '@design/misc/logo-stack/logo-stack.component';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'cc-task-performer-select',
  standalone: true,
  templateUrl: './task-performer-select.component.html',
  styleUrls: ['./task-performer-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownActionComponent,
    DropdownComponent,
    OverlayModule,
    TooltipDirective,
    TranslateModule,
    UserAvatarComponent,
    LogoStackComponent,
  ],
})
export class TaskPerformerSelectComponent {
  @Input()
  taskId: number;

  @Input()
  performer: TaskPerformer | undefined;

  @Input()
  performerCompany: TaskPerformerCompany;

  @Input()
  canChangeAssignee = false;

  @Input()
  canAssignToCurrentUser = false;

  @Input()
  canReroute = false;

  @Input()
  type: 'assignee' | 'assigner' = 'assignee';

  protected dropdownVisible = false;
  protected dropdownPositionStrategy: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 4 },
  ];

  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly tasksService = inject(TasksService);
  private readonly modalService = inject(ModalService); // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.

  assignToCurrentUser(): void {
    this.tasksService.assignToCurrentUser(this.taskId);
  }

  reroute(): void {
    // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.
    this.modalService.open({
      content: ReassignTaskModalComponent,
      inputs: {
        taskId: this.taskId,
        fromConversation: true,
      },
    });
  }
}
